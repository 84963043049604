import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from '../hooks/useRefresh'
import { State, Farm, Pool, Presale, Nft } from '../state/types'
import { fetchFarmsPublicDataAsync, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'

import { QuoteToken } from '../configs/types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync() as any)
    
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid : any): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid)) as any
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol)) as any
  return farm
}

export const useFarmUser = (pid : any) => {
  const farm = useFarmFromPid(pid)

  //console.log('jjjj', farm)
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account : any): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    //console.log('usepool')
    dispatch(fetchPoolsPublicDataAsync() as any)
    if (account) {
      //console.log('usepool user')
      dispatch(fetchPoolsUserDataAsync(account) as any)
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId: any): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId)) as any
  return pool 
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 2 // BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  //console.log(farm)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceMyTokenBusd = (): BigNumber => {
  const pid = 3 // myToken-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
  // const pid = 0 // EGG-BUSD LP
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

// second token price hack
// export const usePricemyTokenBusdB = (): BigNumber => {
//   const pid = 27 // REDBUFF-BUFF
//   const myTokenPriceUSD = usePriceMyTokenBusd()
//   const farm = useFarmFromPid(pid)
//   return farm.tokenPriceVsQuote ? myTokenPriceUSD.times(farm.tokenPriceVsQuote) : ZERO

// }

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const myTokenPrice = usePriceMyTokenBusd()
  //console.log("**", bnbPrice.toString(), myTokenPrice.toString())
  let value = new BigNumber(0)
  
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    //console.log("> ",farm)
    if (farm.lpTotalInQuoteToken) {
      
  
      let val
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = bnbPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.MYTOKEN) {
        val = myTokenPrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)

      //console.log(">++  ",i, " ",farm.lpTotalInQuoteToken,farm.quoteTokenSymbol, value.toString())
    }
  }

  return value

}

export const useTotalValuePools = (): BigNumber => {
  const pools = usePools(null)
  const myTokenPrice = usePriceMyTokenBusd()
  let value = new BigNumber(0)

   for (let i = 0; i < pools.length; i++) {
     const pool = pools[i]
     if (pool.stakingTokenName === QuoteToken.MYTOKEN && pool.totalStaked) {
       const valB = myTokenPrice.times(new BigNumber(pool.totalStaked).div(new BigNumber(10).pow(18)))
       value = value.plus(valB)
     }
   }
  
  return value
}

export const usePresaleData = (): Presale => {
  const presale = useSelector((state: State) => state.presale.data)
  return presale
}

export const useNftData = (): Nft => {
  const nft = useSelector((state: State) => state.nft.data)
  return nft
}

