import "./styles/globals.css";

import { EthereumClient, w3mConnectors } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, sepolia, bsc } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

//import { readContract } from "./lib";
import App from "@/App";

import { BrowserRouter } from "react-router-dom"

import store from './state'

import { Provider } from 'react-redux'
import { RefreshContextProvider } from './hooks/RefreshContext'

import Modal from 'react-modal'
import './index.css'

const apiKey = import.meta.env.VITE_ALCHEMY_API_KEY;
const chainId = import.meta.env.VITE_CHAIN_ID;
const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID;

const chain = chainId === "56" ? bsc : sepolia;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [chain],
  [
    // alchemyProvider({
    //   apiKey,
    // }),
    //publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://bsc-dataseed1.binance.org`,
        webSocket: `wss://bsc-dataseed1.binance.org`,
      }),
    }),


  ]
);

const config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ chains, projectId }),
  publicClient,
  webSocketPublicClient,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(config, chains);
Modal.setAppElement('#root');
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>

    <Provider store={store}>
      <WagmiConfig config={config}>
        <BrowserRouter>
         <RefreshContextProvider>
            <App />
        
          <Web3Modal
            projectId={import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID}
            ethereumClient={ethereumClient}
          />
        </RefreshContextProvider>
        </BrowserRouter> 
      
      </WagmiConfig>

       
      </Provider>
    
  </React.StrictMode>

);
