

import addresses from "@/configs/contracts"


//const chainId = import.meta.env.VITE_CHAIN_ID;

const chainId = import.meta.env.VITE_TESTNETMODE === "TRUE" ? import.meta.env.VITE_CHAIN_ID_TESTNET : import.meta.env.VITE_CHAIN_ID

interface AddressObject {
  [key: number]: string;
}
function getAddress(chainId: number, token: 'referral' | 'mytoken' | 'masterchef' | 'multicall' | 'wbnb' | 'busd' | 'referral' | 'presale' | 'presaleBuyWith'): string | undefined {
  const addressObj: AddressObject = addresses[token];
  return addressObj[chainId];
}

export const getMyTokenAddress = () => {
  return getAddress(parseInt(chainId),'mytoken')
}
export const getMasterChefAddress = () => {
  return getAddress(parseInt(chainId),'masterchef')
}
export const getMulticallAddress = () => {
  return getAddress(parseInt(chainId),'multicall')
}
export const getWbnbAddress = () => {
  return getAddress(parseInt(chainId),'wbnb')
}
export const getBusdAddress = () => {
  return getAddress(parseInt(chainId),'busd')
}
export const geReferralContractAddress = () => {
  return getAddress(parseInt(chainId),'referral')
}
export const getPresaleAddress = () => {
  return getAddress(parseInt(chainId),'presale')
}
export const getPresaleBuyAddress = () => {
  return getAddress(parseInt(chainId),'presaleBuyWith')
}