import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { ethers } from "ethers";
import { useAccount, useContractRead, useDisconnect } from "wagmi";
import { SpinnerDotted } from 'spinners-react';
import { abi, address } from "@/contract/VortexFoundersPacks";

import './App.css';

//import { Routes, Route } from "react-router-loading";

// import Home from "./pages/home";
// import Farms from "./pages/farms";
 import { Link, Route, Routes } from "react-router-dom"
import React, { useEffect, useState, startTransition, Suspense } from "react";

import { useFetchPublicData } from './state/hooks'
import BigNumber from 'bignumber.js'

const Home = React.lazy(() => import('./pages/home'));
const Farms = React.lazy(() => import('./pages/farms'));
const Pools = React.lazy(() => import('./pages/pools'));
const RefPage = React.lazy(() => import('./pages/referrals'));
const HowToPage = React.lazy(() => import('./pages/howto'));
const HomeShort = React.lazy(() => import('./pages/homeShort'));

// import Home from "./pages/home"
// import Farms from "./pages/farms";
// import Pools from "./pages/pools"
// import RefPage from "./pages/referrals"
// import HowToPage from "./pages/howto";

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import { AiOutlineHome } from 'react-icons/ai'
  import { BsMinecartLoaded, BsStackOverflow } from 'react-icons/bs'
  import { TbPigMoney } from 'react-icons/tb'
  import { FaUserFriends } from 'react-icons/fa'
  import { MdOutlineQuestionAnswer } from 'react-icons/md'

  import { themeChange } from 'theme-change';

  import { useLocation } from "react-router-dom";

  //hamburger-react https://hamburger-react.netlify.app/

  // This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function App() {
  const { isOpen, open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { isConnected } = useAccount();

  const { data: price } = useContractRead({
    address,
    abi,
    functionName: "vortexPrice",
  });
  
  // Save referral url
  useEffect(() => {
    const ref = new URLSearchParams(window.location.search).get('ref')
    if (ref) {
      localStorage.setItem('ref', ref)
    }
  }, [])

  useEffect(() => {
    themeChange(false);
  }, []);


  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem('theme') || 'light'
  );
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', currentTheme);
    localStorage.setItem('theme', currentTheme);
  }, [currentTheme]);
  const handleThemeToggle = () => {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light');
  };
  useFetchPublicData()
  const path = useLocation()

  const Spinner = () => {
    return (
    <>
     <SpinnerDotted size={80} thickness={177} speed={80} color="rgba(57, 172, 66, 1)" />
    </>
    )
  }
  return (
    <div className="min-h-screen flex flex-col z-20">
      
     
      <header>

        <nav className="fixed flex h-16 w-full items-center  bg-base-300 px-5 shadow-xl justify-between z-50">
          <div className=" justify-items-start text-secondary">
          
          <div className="flex flex-row gap-2 items-center text-secondary">
          <label className="btn btn-circle btn-ghost swap swap-rotate">
            <input type="checkbox" />
            <svg className="swap-off fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"/></svg>
            <svg className="swap-on fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"/></svg>       
          </label>


  
           
            {`  `}
            <div className="tooltip tooltip-bottom" data-tip="Home">
              <Link to="/"><button className={`btn btn-circle btn-ghost ${path.pathname === '/' ? 'text-accent' : 'text-secondary'}`}><AiOutlineHome color="" size={24}/></button></Link>
            </div>
            {/* {`  `}
            <div className="tooltip tooltip-bottom" data-tip="Farms">
            <Link to="/farms"><button className={`btn btn-circle btn-ghost ${path.pathname === '/farms' ? 'text-accent' : 'text-secondary'}`}><BsMinecartLoaded color="" size={24}/></button></Link>
            </div>
            {`  `}
            <div className="tooltip tooltip-bottom" data-tip="Staking">
            <Link to="/staking"><button className={`btn btn-circle btn-ghost ${path.pathname === '/staking' ? 'text-accent' : 'text-secondary'}`}><BsStackOverflow color="" size={24}/></button></Link>
            </div>
            {`  `}
            <div className="tooltip tooltip-bottom" data-tip="Pools">
            <Link to="/pools"><button className={`btn btn-circle btn-ghost ${path.pathname === '/pools' ? 'text-accent' : 'text-secondary'}`}><TbPigMoney color="" size={24}/></button></Link>
            </div>
            {`  `}
            <div className="tooltip tooltip-bottom" data-tip="Referrals">
            <Link to="/referrals"><button className={`btn btn-circle btn-ghost ${path.pathname === '/referrals' ? 'text-accent' : 'text-secondary'}`}><FaUserFriends color="" size={24}/></button></Link>
            </div>
            {`  `}
            <div className="tooltip tooltip-bottom" data-tip="How To">
            <Link to="/howto"><button className={`btn btn-circle btn-ghost ${path.pathname === '/howto' ? 'text-accent' : 'text-secondary'}`}><MdOutlineQuestionAnswer color="" size={24}/></button></Link>
            </div> */}
            </div>


          </div>

          <div className="flex flex-row items-center">
            <div className="pr-2">
              <label className="btn btn-circle btn-ghost swap swap-rotate text-secondary">
              <input type="checkbox" data-choose-theme checked={currentTheme === 'dark'} onChange={handleThemeToggle}/>
                <svg className="swap-off fill-current w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>
                <svg className="swap-on fill-current w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>
              </label>
            </div>
          
            <Web3Button />
          </div>
         
        </nav>
      </header>
      <main className="flex-grow">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />

            {/* <main className="mt-16 flex grow flex-col items-center justify-center gap-10 bg-gradient-radial from-slate-400 to-slate-700">
              <div className="flex flex-col"> */}
              {/* https://dev.to/collegewap/react-router-get-the-current-route-2e12 */}
              <div className="flex flex-col items-center justify-center mt-12">
              
             <div className="pt-4"></div>
              {/* <select className="gradientselect" data-choose-theme>
        <option disabled value="">
          Pick a theme
        </option>
        <option value="">Default</option>
        <option value="light">Light</option>
        <option value="dark">dark</option>
        <option value="dracula">Dracula</option>
        <option value="cyberpunk">Cyberpunk</option>
        <option value="bumblebee">BubbleBee</option>
 </select> */}




                  <div className="pt-4"></div>
                 
                    <HomeShort />

                  {/* <Routes>
                  <Route
                        index
                        element={
                          <React.Suspense fallback={Spinner()}><Home /></React.Suspense>
                        }
                      />

                    <Route path="/" element={<React.Suspense fallback={Spinner() }><Home /></React.Suspense>}></Route>
                    <Route path="/farms/*" element={<React.Suspense fallback={Spinner()}><Farms /></React.Suspense>}></Route>
                    <Route path="/staking/*" element={<React.Suspense fallback={Spinner()}><Farms tokenMode /></React.Suspense>}></Route>
                    <Route path="/pools/*" element={<React.Suspense fallback={Spinner()}><Pools tokenMode /></React.Suspense>}></Route>
                    <Route path="/referrals" element={<React.Suspense fallback={Spinner()}><RefPage /></React.Suspense>}></Route>
                    <Route path="/howto" element={<React.Suspense fallback={Spinner()}><HowToPage /></React.Suspense>}></Route>

                   
                    <Route path="*" element={<>404</>} />

                </Routes> */}
               {/* <Route path="search" element={<Search />}></Route>
                    <Route path="order/:orderId" element={<OrderDetails />}></Route> */}

              </div>
        </main>
        
        {/* </div>
      </main> */}

<div className="pt-6 "></div>
<footer className="footer footer-center p-8 text-base-content rounded w-full text-xl text-white" >
          
          
 <div className=" bg-center-bottom bg-no-repeat bg-bottom bg-cover w-full h-[500px]  rounded-xl" style={{ backgroundImage: "url('/images/rocketdefi/content/bg-meet.jpg')" }}>

      <div className="grid grid-flow-col gap-4  bg-gray-500 bg-opacity-50 rounded-xl p-4" >
    <a className="link link-hover">About us</a> 
    <a className="link link-hover">Contact</a> 
    <a className="link link-hover">Jobs</a> 
    <a className="link link-hover">Press kit</a>
  </div> 
  <div>
    <div className="grid grid-flow-col gap-4 text-xl  bg-gray-500 bg-opacity-50 rounded-xl p-4">
      <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a> 
      <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a> 
      <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
    </div>
  </div> 
  <div>
    <p className="text-3xl bg-gray-500 bg-opacity-50 rounded-xl p-4">Copyright © 2023 - All right reserved by RocketDefi</p>
  </div>
  </div>
</footer>


    </div>
  );
}


export default  React.memo(App);
