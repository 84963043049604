import BigNumber from 'bignumber.js'
import erc20ABI from '../../abi/erc20.json'
import masterchefABI from '../../abi/masterchef.json'
import { multicall } from '@wagmi/core'
import farmsConfig from '../../configs/farms'
import { getMasterChefAddress } from '../../utils/addressHelpers'

const chainId = import.meta.env.VITE_TESTNETMODE === "TRUE" ? import.meta.env.VITE_CHAIN_ID_TESTNET : import.meta.env.VITE_CHAIN_ID
const CHAIN_ID = 56
const CHAIN_ID_TEST = 97
const CHAIN = chainId == '56' ? CHAIN_ID : CHAIN_ID_TEST

export const fetchFarmUserAllowances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN] : farm.lpAddresses[CHAIN]
    return { 
      address: lpContractAddress,
      abi: erc20ABI,
      functionName: 'allowance', 
      args: [account, masterChefAdress] }
  })

  const rawLpAllowances = await multicall({contracts: calls as any})
  //console.log(rawLpAllowances)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    //console.log(lpBalance)
    return new BigNumber(lpBalance.result as any).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN] : farm.lpAddresses[CHAIN]
    return {
      address: lpContractAddress,
      abi: erc20ABI,
      functionName: 'balanceOf',
      args: [account],
    }
  })

  const rawTokenBalances = await multicall({contracts: calls as any})
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance.result as any).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      abi: masterchefABI,
      functionName: 'userInfo',
      args: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall({contracts: calls as any})
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    const bal = (stakedBalance.result as any)[0]
    //console.log("check here ", bal)
    return new BigNumber(bal as any).toJSON()  //result[0]._hex ??
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      abi: masterchefABI,
      functionName: 'pendingMyToken',
      args: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall({contracts: calls as any})
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings.result as any).toJSON()
  })
  return parsedEarnings
}
