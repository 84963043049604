import pools from '../../configs/pools'
import erc20ABI from '../../abi/erc20.json'
import sousChefABI from '../../abi/sousChef.json'
import { QuoteToken } from '../../configs/types'
import { multicall } from '@wagmi/core'
import { getWeb3 } from '../../utils/web3'
import BigNumber from 'bignumber.js'


const chainId = import.meta.env.VITE_TESTNETMODE === "TRUE" ? import.meta.env.VITE_CHAIN_ID_TESTNET : import.meta.env.VITE_CHAIN_ID
const CHAIN_ID_MAIN = 56
const CHAIN_ID_TEST = 97
const CHAIN_ID = chainId == '56' ? CHAIN_ID_MAIN : CHAIN_ID_TEST

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = pools.filter((p: any) => p.stakingTokenName !== QuoteToken.BNB)
const bnbPools = pools.filter((p: any) => p.stakingTokenName === QuoteToken.BNB)
const web3 = getWeb3()

export const fetchPoolsAllowance = async (account: any) => {
  const calls = nonBnbPools.map((p : any) => ({
    address: p.stakingTokenAddress[CHAIN_ID],
    abi: erc20ABI,
    functionName: 'allowance',
    args: [account, p.contractAddress[CHAIN_ID]],
  }))

  const allowances = await multicall({ contracts: calls as any})
  //console.log('+ allow ', allowances)
  return nonBnbPools.reduce(
    (acc: any, pool: any, index: any) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index].result as any).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account: any) => {
  // Non BNB pools
  //console.log('hoo')
  const calls = nonBnbPools.map((p: any) => ({
    address: p.stakingTokenAddress[CHAIN_ID],
    abi: erc20ABI,
    functionName: 'balanceOf',
    args: [account],
  }))
  const tokenBalancesRaw = await multicall({contracts: calls as any})
  //console.log('+ tokenBalancesRaw ', tokenBalancesRaw)

  const tokenBalances = nonBnbPools.reduce(
    (acc: any, pool: any, index: any) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index].result as any).toJSON() }),
    {},
  )

  // BNB pools
  const bnbBalance = await web3.eth.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc: any, pool: any) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance).toJSON() }),
    {},
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account: any) => {
  const myTokenPools = pools
  const myTokenUserInfo = await multicall(
    {contracts:
      myTokenPools.map((p: any) => ({
      address: p.contractAddress[CHAIN_ID],
      abi: sousChefABI,
      functionName: 'userInfo',
      args: [account],
    })
    ) as any}
  )
  //console.log('+ cakeUserInfo ', cakeUserInfo, )

  return {
    ...pools.reduce(
      (acc: any, pool: any, index: any) => ({
        ...acc,
        [pool.sousId]: new BigNumber((myTokenUserInfo[index].result as any)[0] as any).toJSON(),  //(myTokenUserInfo[index].result as any)?[0]._hex as any).toJSON(),
      }),
      {},
    ),
  }
}

export const fetchUserPendingRewards = async (account: any) => {
  const myTokenPools = pools
  const res = await multicall(
    {contracts:
      myTokenPools.map((p: any) => ({
      address: p.contractAddress[CHAIN_ID],
      abi: sousChefABI,
      functionName: 'pendingReward',
      args: [account],
    })) as any}
  )
  //console.log('+ pendingReward ', res)
  return {
    ...pools.reduce(
      (acc: any, pool: any, index: any) => ({
        ...acc,
        [pool.sousId]: new BigNumber(res[index].result as any).toJSON(),
      }),
      {},
    ),
  }
}
