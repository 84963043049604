import BigNumber from 'bignumber.js'
import erc20 from '../../abi/erc20.json'
import masterchefABI from '../../abi/masterchef.json'
import { getMasterChefAddress, getMulticallAddress } from '../../utils/addressHelpers'
import farmsConfig from '../../configs/farms'
import { QuoteToken } from '../../configs/types'
import { multicall } from '@wagmi/core'

const chainId = import.meta.env.VITE_TESTNETMODE === "TRUE" ? import.meta.env.VITE_CHAIN_ID_TESTNET : import.meta.env.VITE_CHAIN_ID
// const CHAIN_ID_MAIN = 56
// const CHAIN_ID_TEST = 97
// const CHAIN_ID = chainId == '56' ? CHAIN_ID_MAIN : CHAIN_ID_TEST

const fetchFarms = async () => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const CHAIN_ID = 56
      const CHAIN_ID_TEST = 97
      const CHAIN = chainId == '56' ? CHAIN_ID : CHAIN_ID_TEST

      const lpAddress = farmConfig.lpAddresses[CHAIN_ID]
      //console.log ('ggggg', lpAddress, farmConfig.tokenAddresses[CHAIN_ID],farmConfig.quoteTokenAdresses[CHAIN_ID], getMasterChefAddress())
      //console.log("ici" , farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAddress)
      const calls = [
        // Balance of token in the LP contract
        {

          address:  farmConfig.tokenAddresses[CHAIN],
          abi: erc20,
          functionName: 'balanceOf',
          args: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.quoteTokenAdresses[CHAIN] ,
          abi: erc20,
          functionName: 'balanceOf',
          args: [ lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN] : lpAddress    ,
          abi: erc20,
          functionName: 'balanceOf',
          args: [getMasterChefAddress()],

          // address: lpAddress ,
          // abi: erc20,
          // functionName: 'balanceOf',
          // args: [farmConfig.quoteTokenAdresses[CHAIN_ID] ],

        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          abi: erc20,
          functionName: 'totalSupply',
        },
        // Token decimals
        {
          address: farmConfig.tokenAddresses[CHAIN],
          abi: erc20,
          functionName: 'decimals',
        },
        // Quote token decimals
        {
          address: farmConfig.quoteTokenAdresses[CHAIN],
          abi: erc20,
          functionName: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall({contracts: calls as any})
     
      //console.log('ooo', tokenBalanceLP, quoteTokenBlanceLP, lpTokenBalanceMC, farmConfig.quoteTokenAdresses[CHAIN_ID])

      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote
      if (farmConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC.result as any).div(new BigNumber(10).pow(tokenDecimals.result as any))
        if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP.result as any).div(new BigNumber(tokenBalanceLP.result as any))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC.result as any).div(new BigNumber(lpTotalSupply.result as any))
        //console.log('k ',lpTokenRatio.toJSON())
        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP.result as any)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenRatio)
          //console.log(quoteTokenBlanceLP.result )
        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP.result as any).div(new BigNumber(10).pow(tokenDecimals.result as any)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP.result as any)
          .div(new BigNumber(10).pow(quoteTokenDecimals.result as any))
          .times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP.result as any).div(new BigNumber(tokenBalanceLP as any))
        }
      }
      
      const [info, totalAllocPoint, myTokenPerBlock] = await multicall({contracts: [
        {
          address: getMasterChefAddress() as any,
          abi: masterchefABI as any,
          functionName: 'poolInfo',
          args: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress() as any,
          abi: masterchefABI as any,
          functionName: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          abi: masterchefABI as any,
          functionName:  'myTokenPerBlock',
        },
      ] })
      
      //console.log(info, totalAllocPoint, mytokenPerBlock)
     
      const allocPoint = new BigNumber( (info.result as any[])[1] )//info.allocPoint)
      const poolWeight = allocPoint.div(totalAllocPoint.result as any)  //totalAllocPoint))

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: (info.result as any[])[4],//info.depositFeeBP,
        myTokenPerBlock: new BigNumber(myTokenPerBlock.result as any).toNumber(),
        lpTokenBalance: new BigNumber(lpTokenBalanceMC.result as any).toJSON()
      }
    }),
  )
  return data
}

export default fetchFarms
