import poolsConfig from '../../configs/pools'
import sousChefABI from '../../abi/sousChef.json'
import myTokenABI from '@/abi/myToken.json'
//import multicall from '../../utils/multicall'
import BigNumber from 'bignumber.js'
import { multicall } from '@wagmi/core'

const chainId = import.meta.env.VITE_TESTNETMODE === "TRUE" ? import.meta.env.VITE_CHAIN_ID_TESTNET : import.meta.env.VITE_CHAIN_ID



export const fetchPoolsBlockLimits = async () => {
  const myTokenPools = poolsConfig
  const CHAIN_ID_MAIN = 56
  const CHAIN_ID_TEST = 97
  const CHAIN_ID = chainId == '56' ? CHAIN_ID_MAIN : CHAIN_ID_TEST

  const myTokenStartsCalls = myTokenPools.map((myTokenPool) => {
    const callPool = {
      address: myTokenPool.contractAddress[CHAIN_ID] as any,
      abi: sousChefABI,
      functionName: 'startBlock',
    };
    return callPool;
  });

  const myTokenEndsCalls = myTokenPools.map((myTokenPool) => {
    const callPool = {
      address: myTokenPool.contractAddress[CHAIN_ID] as any,
      abi: sousChefABI,
      functionName: 'bonusEndBlock',
    };
    return callPool;
  });

  const myTokenStarts = await multicall({contracts: myTokenStartsCalls as any})
  const myTokenEnds = await multicall({contracts: myTokenEndsCalls as any})
  //console.log('+ myTokenStarts ', myTokenStarts)
  //console.log('+ myTokenEnds ', myTokenEnds)
  // const myTokenStarts = await multicall(
  //   sousChefABI,
  //   myTokenPools.map((myTokenPool) => ({
  //     address: myTokenPool.contractAddress[CHAIN_ID] as any,
  //     name: 'startBlock',
  //   })),
  // )
  // const myTokenEnds = await multicall(
  //   sousChefABI,
  //   myTokenPools.map((myTokenPool) => ({
  //     address: myTokenPool.contractAddress[CHAIN_ID] as any,
  //     name: 'bonusEndBlock',
  //   })),
  // )

  return [
    ...myTokenPools.map((poolConfig, index) => {
      const startBlock = myTokenStarts[index].result as any
      const endBlock = myTokenEnds[index].result as any

      return {
        sousId: poolConfig.sousId,
        startBlock: new BigNumber(startBlock).toJSON(),
        endBlock: new BigNumber(endBlock).toJSON(),
      }
    }),
  ]
}

export const fetchPoolsTotalStaking = async () => {
  const CHAIN_ID_MAIN = 56
  const CHAIN_ID_TEST = 97
  const CHAIN_ID = chainId == '56' ? CHAIN_ID_MAIN : CHAIN_ID_TEST

  const myTokenPools = poolsConfig as any

  const myTokenBalancesCalls = myTokenPools.map((myTokenPool : any) => {
    const callPool = {
      address: myTokenPool.stakingTokenAddress[CHAIN_ID] as any,
      abi: myTokenABI,
      functionName: 'balanceOf',
      args: [myTokenPool.contractAddress[CHAIN_ID]] as any
    };
    return callPool;
  });

  const myTokenPoolsTotalStaked = await multicall({contracts: myTokenBalancesCalls as any})
  //console.log('+ myTokenPoolsTotalStaked ', myTokenPoolsTotalStaked)
  // const myTokenPoolsTotalStaked = await multicall(
  //   myTokenABI,
  //   myTokenPools.map((myTokenPool : any) => ({
  //     address: myTokenPool.stakingTokenAddress[CHAIN_ID] as any,
  //     name: 'balanceOf',
  //     params: [myTokenPool.contractAddress[CHAIN_ID]] as any,
  //   })),
  // )

  return [
    ...myTokenPools.map((p : any, index: any) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(myTokenPoolsTotalStaked[index].result as any).toJSON(),
    })),
  ]
}
