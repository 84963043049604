import random from 'lodash/random'

// Array of available nodes to connect to
const nodes = [import.meta.env.VITE_APP_NODE_1, import.meta.env.VITE_APP_NODE_2, import.meta.env.VITE_APP_NODE_3]
const testNodes = [import.meta.env.VITE_APP_NODE_4, import.meta.env.VITE_APP_NODE_5, import.meta.env.VITE_APP_NODE_6]

const nodeArr = import.meta.env.VITE_APP_TEST_MODE === "T" ? testNodes : nodes; 
//console.log("---- ", nodeArr, import.meta.env.VITE_APP_NODE_4)
const getNodeUrl = () => {
  const randomIndex = random(0, nodeArr.length - 1)
  return nodeArr[randomIndex]
}

export default getNodeUrl
