

const addresses = {
  
  referral: {
    56: "0xAF62F1A47d5655250a7884485ac13FaCBfC01DAd",
    97: '0xAF62F1A47d5655250a7884485ac13FaCBfC01DAd',
  },
  mytoken: {
    56: '0x10a49f1fC8C604eA7f1c49bcc6ab2A8E58e77EA5',
    97: '0x10a49f1fC8C604eA7f1c49bcc6ab2A8E58e77EA5',
  },
  masterchef: {
    56: '0x67D26cF7e6CB68feE0Cf546Ac489691d961c97da',
    97: '0x67D26cF7e6CB68feE0Cf546Ac489691d961c97da',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  multicall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
  },
  presale: {
    56: '0x586A07F991B7ba538f8ddBF906c22f6feA4ee8A7',
    97: '0x586A07F991B7ba538f8ddBF906c22f6feA4ee8A7',
  },
  presaleBuyWith: {
    56: '0xfdCF6F480838e7b12f091dACa213Ef571782b79C',
    97: '0xfdCF6F480838e7b12f091dACa213Ef571782b79C',
  },
  horn: {
    56: '0xa750771CbDc5e52D0B3233ECf007C690889c0127',
    97: '0x3Ae4921B4E8FE6DDdef35B8e2459FE1938a252B3',
  },
  nft: {
    56: '0xDd39Eea28d6f56f63619585617066b7795597701',
    97: '0xDd39Eea28d6f56f63619585617066b7795597701',
  },
}

export default addresses