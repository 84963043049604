/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig from '../../configs/farms'
import fetchFarms from './fetchFarms'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from './fetchFarmUser'
import { FarmsState, Farm } from '../types'
import BigNumber from 'bignumber.js'

const initialState: FarmsState = { data: [...farmsConfig] as any }

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.pid  === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl: { index: any }) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl as any }
      })
    },
  },
})

// Actions
export const { setFarmsPublicData, setFarmUserData } = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch: (arg0: { payload: any; type: "Farms/setFarmsPublicData" }) => void) => {
  const farms = await fetchFarms()
  dispatch(setFarmsPublicData(farms))
}
export const fetchFarmUserDataAsync = (account: any) => async (dispatch: (arg0: { payload: any; type: "Farms/setFarmUserData" }) => void) => {

  //console.log('fetch user farm', account)
  const userFarmAllowances = await fetchFarmUserAllowances(account)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account)
  const userStakedBalances = await fetchFarmUserStakedBalances(account)
  const userFarmEarnings = await fetchFarmUserEarnings(account)
 //console.log(userFarmAllowances, userFarmEarnings)
  const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance: any, index: string | number) => {
    return {
      index,
      allowance: userFarmAllowances[index as any],
      tokenBalance: userFarmTokenBalances[index as any],
      stakedBalance: userStakedBalances[index as any],
      earnings: userFarmEarnings[index as any],
    }
  })

  dispatch(setFarmUserData({ arrayOfUserDataObjects }))
}

export default farmsSlice.reducer
