import { configureStore } from '@reduxjs/toolkit'
import farmsReducer from './farms'
import poolsReducer from './pools'
// import presaleReducer from './presale'
// import nftReducer from './nft'

export default configureStore({
  devTools: import.meta.env.NODE_ENV !== 'production',
  reducer: {
    farms: farmsReducer,
    pools: poolsReducer,
    // presale: presaleReducer,
    // nft: nftReducer
  },
 
})

// middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//  serializableCheck: false
// }),