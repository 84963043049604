import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const MYTOKEN_TICKER = 'MYTICKER'
export const MYTOKEN_LongName = "MyToken"
export const MYWEBSITE_Name = "Cool Website"
export const MYWEBSITE_URL = "https://www.myurl.com"
export const MYDEX_URL = "https://pancakeswap.org"

export const MYTOKEN_PER_BLOCK = new BigNumber(1)
export const BLOCKS_PER_YEAR = new BigNumber(10512000)
export const BSC_BLOCK_TIME = 3

export const MYTOKEN_POOL_PID = 1


import farmsConfig from './farms'

const communityFarms = farmsConfig.filter((farm) => farm.isCommunity).map((farm) => farm.tokenSymbol)

export { farmsConfig, communityFarms }
export { default as poolsConfig } from './pools'
//export { default as ifosConfig } from './ifo'
